import Vue from "vue";
import Vuex from "vuex";
import { user } from "@/api/login";
import { setToken, getToken } from "@/utils/auth";
import { Message } from "element-ui";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: getToken(),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },
  actions: {
    login({ commit }, userInfo) {
      // login({ commit }) {
      const { username, password, imageCode } = userInfo;
      return new Promise((resolve, reject) => {
        user
          .login({
            username: username.trim(),
            password: password,
            imageCode: imageCode,
          })
          .then((response) => {
            // console.error("response loin====", response, response.headers);
            const { data, headers } = response;
            if (data.code === 0) {
              commit("SET_TOKEN", headers.authorization);
              headers.password = password;
              setToken(headers.authorization);
            } else {
              Message({
                message: data.msg || "Error",
                type: "error",
                duration: 5 * 1000,
              });
            }
            resolve(headers);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    dlogin({ commit }, userInfo) {
      // login({ commit }) {
      const { mobile, code } = userInfo;
      return new Promise((resolve, reject) => {
        user
          .dlogin({
            mobile: mobile.trim(),
            code: code,
          })
          .then((response) => {
            // console.error("response loin====", response, response.headers);
            const { data, headers } = response;
            if (data.code === 0) {
              commit("SET_TOKEN", headers.authorization);
              headers.password = code;
              setToken(headers.authorization);
            } else {
              Message({
                message: data.msg || "Error",
                type: "error",
                duration: 5 * 1000,
              });
            }
            resolve(headers);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // user logout 退出登录
    // logout() {
    //   return new Promise((resolve) => {
    //     removeToken(); // must remove  token  first
    //     resolve();
    //     // logout(state.token).then(() => {
    //     //   removeToken() // must remove  token  first
    //     //   resetRouter()
    //     //   commit('RESET_STATE')
    //     //   resolve()
    //     // }).catch(error => {
    //     //   reject(error)
    //     // })
    //   });
    // },
  },
  modules: {},
});
