import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: { title: "首页" },
    redirect: "/login",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/login",
        component: () => import("../views/login/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
