import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/icons/index.js";
import "element-ui/lib/theme-chalk/index.css";
import { Button, Form, FormItem, Input, Checkbox } from "element-ui"; // 按需引入组件

// import { detectZoom } from "@/utils/detectZoom.js";

Vue.component(Button.name, Button); // 注册全局组件
Vue.component(Form.name, Form); // 注册全局组件
Vue.component(FormItem.name, FormItem); // 注册全局组件
Vue.component(Input.name, Input); // 注册全局组件
Vue.component(Checkbox.name, Checkbox); // 注册全局组件

Vue.config.productionTip = false;
// const m = detectZoom();

// switch (m) {
//   // 4k屏时屏幕缩放比为100%
//   case 100:
//     document.body.style.zoom = 100 / 50;
//     break;
//   // 4k屏时屏幕缩放比为125%
//   case 125:
//     document.body.style.zoom = 100 / 62.5;
//     break;
//   // 4k屏时屏幕缩放比为150%
//   case 150:
//     document.body.style.zoom = 100 / 75;
//     break;
//   // 4k屏时屏幕缩放比为175%
//   case 175:
//     document.body.style.zoom = 100 / 87.4715;
//     break;
//   // 4k屏时屏幕缩放比为200%
//   case 200:
//     document.body.style.zoom = 100 / 100;
//     break;
//   // 4k屏时屏幕缩放比为225%
//   case 225:
//     document.body.style.zoom = 100 / 112.485;
//     break;

//   default:
//     break;
// }
//判断屏幕是否为4k
// if (
//   window.screen.width * window.devicePixelRatio >= 3840 ||
//   window.devicePixelRatio === 2
// ) {
//   document.body.style.zoom = 100 / (Number(m) / 2);
// } else {
//   document.body.style.zoom = 100 / Number(m);
// }

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
