<template>
  <div class="home">
    <router-view v-slot="{ Component }">
      <component :is="Component" :key="key"
    /></router-view>
  </div>
</template>

<script>
import router from "../router";
export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  computed: {
    key() {
      return router.currentRoute.path;
    },
  },
  mounted() {},
  methods: {},
};
</script>
