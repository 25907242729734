import ajax from "@/utils/request.js";
export const user = {
  login: (data) => ajax.post("/login", data),
  dlogin: (data) => ajax.post("/sms/login", data),
  getValidateCode: (data) =>
    ajax.get("/validateCode/image", {
      responseType: "arraybuffer",
      params: data,
    }),
  register: (data) => ajax.post("/register/save", data),
  // 发送登陆短信验证
  sendLoginCode: (data) =>
    ajax.get("/sms/sendLoginCode", {
      params: data,
    }),
  // 发送注册短信验证
  sendRegisterCode: (data) =>
    ajax.get("/sms/sendRegisterCode", {
      params: data,
    }),
  //检验注册短信验证码
  checkRegister: (data) => ajax.post("/sms/checkRegisterSmsCode", data),
};
